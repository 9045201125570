import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero"
import UnderDevelopment from "../components/UnderDevelopment"

const Roombooking = () => {
  return (
    <Layout>
      <SEO title="Room Booking" />
      <Hero className="" englishTitle="Room Booking" syllabics=""></Hero>
      <UnderDevelopment />
    </Layout>
  )
}

export default Roombooking
