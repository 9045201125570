import React from "react"

const UnderDevelopment = () => {
  return (
    <div className="bg-gray-light py-20 flex justify-center leading-4 text-center px-6">
      <h2>
        This page is currently under development.
        <br />
        Please check back again soon.
      </h2>
    </div>
  )
}

export default UnderDevelopment
